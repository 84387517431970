/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Service from './Service'

/**
 * A call to action component..
 */
class Cta extends Component {
  getComponentForType = type => {
    let component
    switch (type) {
    case 'service':
      component = <Service {...this.props}/>
      break
    default:
      component = new Error('Invalid CTA type: ' + type)
    }
    return component
  }
  render () {
    const {
      type
    } = this.props
    return (
      <div>
        {this.getComponentForType(type)}
      </div>
    )
  }
}

Cta.propTypes = {
  type: PropTypes.oneOf(['service']).isRequired
}

export default Cta
