/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from '../components/Seo'
import { Link } from 'gatsby'
import All from '../components/Articles/All'
import Cta from '../components/Cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from './../data/SiteConfig'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

/**
 * Blog page containing a list of articles.
 */
class BlogPage extends Component {
  render () {
    return (
      <DefaultLayout className={`page-blog`}>
        <SEO
          pageType={`article-list`}
          pageTitle={`List of articles`}
          fullPageTitle={`List of articles by Keith Mifsud`}
          pageDescription={`A collection of articles by Keith Mifsud. The articles are about software engineering and software development.`}
          pageImage={config.siteImage}
          pagePath={`/blog`}
        />
        <div className="container">
          <div className="row pt-lg-12 intro pt-8 special-padding">
            <section className="col-12 content text-center">
              <h1>List of articles</h1>
              <p className={`text-secondary lead px-lg-8 pt-3`}>I write articles with the purpose of sharing what I learn in my day to day work. I hope you'll find them useful and I also hope to be able to publish and share more tutorials, best practices and of course, my opinion on technology matters.</p>
              <p className={`pt-3`}><Link to={`/subscribe`} className={`hover-none hover-darken`} title={`Subscribe`}>Subscribe</Link> to get notified when I publish new articles.</p>
              <div className="row justify-content-center pt-lg-0 pt-3">
                <div className="col-lg-2 col-4">
                  <p className={`icons`}>
                    <a
                      href={config.defaultAuthor.socialProfiles.twitter}
                      title={`Follow Keith Mifsud on Twitter`}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                      className={`mr-3 hover-darken`}
                      style={{ fontSize: '1.6rem' }}
                    ><FontAwesomeIcon icon={['fab', 'twitter']}/>
                    </a>
                    <a
                      href={config.siteUrl + config.rssFeedPath}
                      title={`Get RSS feed`}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                      className={`hover-darken`}
                      style={{ fontSize: '1.6rem' }}
                    ><FontAwesomeIcon icon={['fas', 'rss']}/>
                    </a>
                  </p>
                </div>
              </div>
              <div className="row d-sm-none justify-content-center pt-3">
                <div className="col-6">
                  <AnchorLink href={`#start-list`}>
                    <button className={`btn btn-outline-secondary btn-lg`} style={{ maxHeight: '48px' }}>
                      Read <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.875rem' }}/>
                    </button>
                  </AnchorLink>
                </div>
              </div>
            </section>
          </div>
          <div className="row pt-lg-10 pt-10" id={`start-list`}>
            <div className="col-lg-9 list">
              <div className="d-none d-sm-block">
                <All orientation={'vertical'}/>
              </div>
              <div className="d-sm-none recent-articles-container">
                <All orientation={'horizontal'}/>
              </div>
            </div>
            <aside className="col-lg-3 pt-lg-0 pt-5">
              <div
                className="sidebar-content border ml-lg-5 sticky-top sticky-offset-header rounded"
              >
                <Cta
                  type={`service`}
                  serviceName={`authoring`}
                  actionText={`Get in touch`}
                  serviceLink={`/services/technical-authoring`}
                >
                  <div className="cta-content">
                    <p><strong>Did you enjoy reading these articles?</strong></p>
                    <p>I can write tutorials, articles, technical specifications, user guides and documentation for your products and company.</p>
                  </div>
                </Cta>
              </div>
            </aside>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPage
