/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Icon from '../../Icon'

/**
 * Call to action for a service.
 */
class ServiceCta extends Component {
  /**
   * Gets the CTA content.
   *
   * @param serviceNode
   * @param serviceName
   * @param actionText
   * @param serviceLink
   * @param children
   * @return {*}
   */
  getServiceCta = (
    serviceNode,
    serviceName,
    actionText,
    serviceLink,
    children
  ) => {
    let service
    if (serviceNode.name === serviceName) {
      service = <div key={serviceNode.key}>
        <Icon type={serviceNode.icon}/>
        <h2 className={`pb-5 pt-3`}>{serviceNode.title}</h2>
        <div className={`py-3 text-secondary`}>{children}</div>
        <Link to={serviceLink} title={serviceNode.title} className={`hover-none hover-darken`}>
          <p><small>more details ...</small></p>
        </Link>
        <Link to="/contact" className={`btn btn-warning btn-lg`}>
          {actionText}
        </Link>
      </div>
    }
    return service
  }

  render () {
    const {
      serviceName,
      actionText,
      serviceLink,
      children
    } = this.props
    return (
      <div className={`service-cta`}>
        <StaticQuery
          query={graphql`
            query {
              allServicesJson {
                edges{
                  node{
                    key
                    name
                    title
                    description
                    featured
                    icon
                    order
                  }
                }
              }
            }
          `}
          render={data =>
            <div className="text-center px-3 py-5">
              {data.allServicesJson.edges.map(({ node }, index) => (
                this.getServiceCta(
                  node,
                  serviceName,
                  actionText,
                  serviceLink,
                  children
                )
              ))}
            </div>

          }
        />
      </div>
    )
  }
}

ServiceCta.defaultProps = {
  serviceLink: '/services'
}

ServiceCta.propTypes = {
  serviceName: PropTypes.oneOf([
    'authoring',
    'development',
    'lead',
    'turnkey',
    'workshop',
    'consultancy'
  ]).isRequired,
  actionText: PropTypes.string.isRequired,
  serviceLink: PropTypes.string
}

export default ServiceCta
