/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isLastElementInArray, isDesktop } from '../../../../lib/helpers'

/**
 * Renders a vertical list of all articles.
 */
class VerticalList extends Component {
  /**
   * Toggles between left and right depending on
   * whether the index is even or odd.
   *.
   * @param itemIndex
   * @return {string}
   */
  getAlignment = itemIndex => {
    let alignment = 'right'
    if (isDesktop()) {
      if (itemIndex % 2 === 0) {
        alignment = 'left'
      }
    } else {
      alignment = 'left'
    }
    return alignment
  }

  /**
   * Gets a linked preview image.
   *
   * @param articlePath
   * @param articleTitle
   * @param fluidImage
   * @param imageAlignment
   * @return {*}
   */
  getPreviewImage = (articlePath, articleTitle, fluidImage, imageAlignment) => {
    let borderAlignment = 'rounded-' + imageAlignment
    return (<Link to={articlePath} title={articleTitle}>
      <Img
        fluid={fluidImage}
        className={`img-fluid ${ borderAlignment }`}
      />
    </Link>)
  }

  render () {
    return (
      <>
        <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                sort: {fields: [frontmatter___date], order: DESC}
                filter: {
                  fields:{
                    collection: {eq: "posts"}
                  }
                }
            ){
                edges{
                  node{
                    timeToRead
                    excerpt(pruneLength: 280)
                    frontmatter{
                      cover_image{
                        publicURL
                        childImageSharp{
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      date(formatString: "Do MMMM, YYYY")
                      title
                      path
                      tags
                      featured
                    }
                  }
                }
              }
            }
          `}
           render={data =>
             <div className="row">
               {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
                 <div key={node.frontmatter.title} className={`col-12 ${ isLastElementInArray(index, arr.length) === false && `mb-8` }`}>
                   <div className="border rounded row no-gutters article-card-horizontal">
                     {this.getAlignment(index) === 'left' &&
                     <div className="col-lg-6 col-sm-12">
                       {this.getPreviewImage(
                         node.frontmatter.path,
                         node.frontmatter.title,
                         node.frontmatter.cover_image.childImageSharp.fluid,
                         this.getAlignment(index)
                       )}
                     </div>
                     }
                     <div className={`col-lg-6 col-sm-12 py-3 px-3`}>
                       <Link
                         to={node.frontmatter.path}
                         title={node.frontmatter.title}
                         className={`hover-none`}
                       >
                         <h3
                          className={`hover-lighten`}
                         >
                           {node.frontmatter.title}
                         </h3>
                       </Link>
                       <div>
                           <small className="text-muted">
                             Published on {node.frontmatter.date}
                           </small>
                       </div>
                       <div className="tags">
                         {node.frontmatter.tags.map(tag =>
                           <span key={tag} className="badge badge-secondary mr-2">
                             {tag}
                           </span>
                         )}
                       </div>
                       <p className={`text-secondary small pt-5`}>
                         {node.excerpt}
                       </p>
                       <div className={`bg-white border-0 time-to-read text-${ this.getAlignment(index + 1) } pt-2`}>
                         <small className={`text-muted`}><FontAwesomeIcon icon={['far', 'clock']}/> {node.timeToRead} min read</small>
                       </div>
                     </div>
                     {this.getAlignment(index) === 'right' &&
                     <div className="col-lg-6 d-sm-none d-md-block">
                       {this.getPreviewImage(
                         node.frontmatter.path,
                         node.frontmatter.title,
                         node.frontmatter.cover_image.childImageSharp.fluid,
                         this.getAlignment(index)
                       )}
                     </div>
                     }
                   </div>
                 </div>
               ))}
             </div>
           }
        />
      </>
    )
  }
}

export default VerticalList
