/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VerticalList from './VerticalList'
import HorizontalList from './HorizontalList'

/**
 * List of all articles.
 */
class AllArticles extends Component {
  render () {
    const { orientation } = this.props
    let listType

    if (orientation === 'vertical') {
      listType = <VerticalList/>
    } else if (orientation === 'horizontal') {
      listType = <HorizontalList/>
    }

    return (
      <div className={`article-list`}>
        {listType}
      </div>
    )
  }
}

AllArticles.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal']).isRequired
}

export default AllArticles
